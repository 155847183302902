import React, { useState, useEffect } from 'react';
import {
    Checkbox,
    Input,
    HStack,
    Text,
    IconButton
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

import Const from './Constants';

const EditableCheckbox = ({
  value,
  object,
  setGlobalCheckableEditState,
  onChange,
  onDelete
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);  // Track hover state

  const [text, setText] = useState(value);

  useEffect(() => {
    // Make sure component is being updated when value is changed.
    setText(value);
  }, [value]);

  useEffect(() => {
    if(!text) {
      setIsEditing(true);
    }
  }, [text]);

  useEffect(() => {
    setGlobalCheckableEditState(isEditing);
  // eslint-disable-next-line
  }, [isEditing]);

  const handleCheckboxChange = () => {
    const newCheckedState = !isChecked;

    onChange(object, null, newCheckedState === true ? Const.sDone : Const.sOpen); // No text change, only status

    setIsChecked(newCheckedState);
  };

  const handleTextClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);

    if(!text) {
      onDelete(object);
    }

    if(text !== value) {
      onChange(object, text, null); // No status change on text edit
    }
  };

  const handleInputChange = (e) => {
    setText(e.target.value);
  };

  const handleDelete = () => {
    if (onDelete) {
      onDelete(object); // Call the onDelete handler to delete the item
    }
  };

  return (
    <HStack
      spacing={2}
      onMouseEnter={() => setIsHovered(true)}   // Set hover state on enter
      onMouseLeave={() => setIsHovered(false)}  // Reset hover state on leave
    >
      <Checkbox
        isChecked={isChecked}
        onChange={handleCheckboxChange}
        colorScheme={'green'}
        sx={{
            'span.chakra-checkbox__control': {
              borderWidth: '1px',
              borderColor: Const.White
            }
        }}        
      />
      {isEditing ? (
        <Input
          value={text || ''}
          autoFocus
          onChange={handleInputChange}
          onBlur={handleBlur}
          onKeyDown={(event) => {
            if (event.key === Const.sEnter) {
              event.preventDefault(); // Prevent default 'Enter' behavior if necessary
              handleBlur();
            }
          }}
          fontSize={Const.SM}
          variant={'unstyled'}
        />
      ) : (
        <Text
          onClick={handleTextClick}
          style={{ cursor: Const.sPointer }}
          fontSize={Const.SM}
          noOfLines={1}
          textAlign={Const.Left}
        >
          {text}
        </Text>
      )}
      {/* Show the X button only when hovered */}
      {isHovered && !isEditing && (
        <IconButton
          icon={<CloseIcon/>}
          color={Const.Red500}
          size={Const.XS}
          fontSize={Const.XXS}
          onClick={handleDelete}
          variant='unstyled'   // Unstyled variant to remove background and border
          _hover={{ color: Const.Red700 }}
          p={0}                // Remove padding
          w='16px'             // Set fixed width for the button
          h='16px'             // Set fixed height for the button
          display='flex'
          alignItems={Const.sCenter}  // Ensure the icon is centered vertically
          justifyContent={Const.sCenter} // Ensure the icon is centered horizontally
        />
      )}
    </HStack>
  );
};

export default EditableCheckbox;
