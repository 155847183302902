/*
We want to have:
- Agenda for the next meeting, not to forget to discuss - checkbox items list
- Action items - for both?
- Meeting notes log of every meeting every time, grouped by date

AI part
- AI notes summary
- Perf review AI generation
*/

import React, { useState, useEffect, useRef } from 'react';

import {
    Text,
    VStack,
    Divider,
    Box,
    HStack,
    Button,
    Textarea,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Input,
    Spacer
} from '@chakra-ui/react';

import { AddIcon, DeleteIcon } from '@chakra-ui/icons';

import Const from './Constants';
import { fsDateToString, sortByTitle } from './Utils';

import EditableCheckbox from './EditableCheckbox';

const MeetingNoteTextarea = ({
    auth,
    meetingNote,
    meetingsData,
    setMeetingsData,
    currentMeeting,
    setCurrentMeeting
}) => {
    // Internal state
    const [internalMeetingNote, setInternalMeetingNote] = useState(meetingNote);

    // Update local value when initialValue changes (from parent component re-render)
    useEffect(() => {
        setInternalMeetingNote(meetingNote);
    // eslint-disable-next-line
    }, [meetingNote]);

    const handleChange = (event) => {
        const value = event.target.value;

        if(value !== internalMeetingNote.body) {
            let copyInternalMeetingNote = JSON.parse(JSON.stringify(internalMeetingNote));
            copyInternalMeetingNote.body = value;
            setInternalMeetingNote(copyInternalMeetingNote);
        }
    };

    const handleBlur = () => {
        if (internalMeetingNote.body !== meetingNote.body) {
            handleAddOrUpdateMeetingNotes();
        }
    };

    const handleAddOrUpdateMeetingNotes = async () => {
        // -- Update server state
        if(!internalMeetingNote.id) { // Add new note case
            const idToken = await auth.currentUser.getIdToken();
              const theURL = process.env.REACT_APP_BASE_URL + Const.sMeetingsNotesPath;
              const response = await fetch(theURL, {
                method: Const.HttpPost,
                headers: new Headers({
                  Authorization: idToken,
                  Accept: Const.AppJson,
                  [Const.ContentType]: Const.AppJson
                }),
                body: JSON.stringify({
                  meetingId: internalMeetingNote.meetingId,
                  body: internalMeetingNote.body
                })
              });
        
              // Check if the request was successful
              if(!response.ok) {
                throw new Error('Failed to add meeting note');
              }
        
              // Parse the added note from the response
              const addedMeetingNote = await response.json();

              // Update the id as well
              let copyInternalMeetingNote = JSON.parse(JSON.stringify(internalMeetingNote));
              copyInternalMeetingNote.id = addedMeetingNote.id;
              setInternalMeetingNote(copyInternalMeetingNote);

              // State is not being updated instantly, even if we do await so update the object here so we can use id in this function.
              internalMeetingNote.id = addedMeetingNote.id;
        }
        else { // Update note case
            const idToken = await auth.currentUser.getIdToken();
              const theURL = process.env.REACT_APP_BASE_URL + Const.sMeetingsNotesPath;
              const response = await fetch(theURL, {
                method: Const.HttpPatch,
                headers: new Headers({
                  Authorization: idToken,
                  Accept: Const.AppJson,
                  [Const.ContentType]: Const.AppJson
                }),
                body: JSON.stringify({
                  meetingNoteId: internalMeetingNote.id,
                  body: internalMeetingNote.body
                })
              });
        
              // Check if the request was successful
              if(!response.ok) {
                throw new Error('Failed to update meeting note');
              }

              // Note already got an id as this is an update, no need to take care of the id.
        }

        // -- Update local state
        // - Update current one
        let copyCurrentMeeting = JSON.parse(JSON.stringify(currentMeeting));

        copyCurrentMeeting.data.meetingNotes.forEach((meetingNote, index) => {
            if (meetingNote.id === internalMeetingNote.id ||
                meetingNote.id === undefined) { // Or the case when we add a note, not an update
                  copyCurrentMeeting.data.meetingNotes[index] = JSON.parse(JSON.stringify(internalMeetingNote));
            }
        });

        setCurrentMeeting(copyCurrentMeeting);

        // - Update the whole data

        let copyMeetingsData = JSON.parse(JSON.stringify(meetingsData));

        copyMeetingsData.forEach((meeting, index) => {
            if (meeting.id === currentMeeting.id) {
              copyMeetingsData[index].data.meetingNotes.forEach((meetingNote, index2) => {
                    if(meetingNote.id === internalMeetingNote.id ||
                        meetingNote.id === undefined) { // Or the case when we add a note, not an update
                          copyMeetingsData[index].data.meetingNotes[index2] = internalMeetingNote;
                    }
                });
            }
        });

        setMeetingsData(copyMeetingsData);
      };

    return (
        <Textarea
            value={internalMeetingNote.body}
            onChange={handleChange}
            onBlur={handleBlur}
            fontSize={Const.MD}
            flex={'1'}
            h={'100%'}
            resize={'none'}
            borderColor={'gray.300'}
            placeholder={'Enter meeting notes here'}
            _placeholder={{ color: 'gray.500' }}
            _hover={{ cursor: 'text' }}
        />
    );
};

const MeetingsDetails = ({
  type,
  auth,
  meetingsData,
  setMeetingsData,
  currentMeeting,
  setCurrentMeeting
}) => {
  // Used as a value for the title input
  const [title, setTitle] = useState(currentMeeting ? currentMeeting.title : '');
  const titleInputRef = useRef(null);

  // This is a state that describes if there is any checkable being edited right now.
  // This is needed to fix a bug when people will hit add button while editing a checkable.
  const [globalCheckableEditState, setGlobalCheckableEditState] = useState(false);

  useEffect(() => {
    if(currentMeeting) {
      if(currentMeeting.title !== title) {
        setTitle(currentMeeting.title);
      }

      // If the current item title is empty set the focus on title input
      if(currentMeeting.title === '' && titleInputRef.current) {
        titleInputRef.current.focus();
      }
    }
  // eslint-disable-next-line
  }, [currentMeeting]);

  async function handleDeleteMeeting(meetingId) {
    try {
      const idToken = await auth.currentUser.getIdToken();
      const theURL = process.env.REACT_APP_BASE_URL + Const.sMeetingsPath;
      await fetch(theURL, {
        method: Const.HttpDelete,
        headers: new Headers({
          Authorization: idToken,
          Accept: Const.AppJson,
          [Const.ContentType]: Const.AppJson
        }),
        body: JSON.stringify({
          meetingId
        })
      });

      // Delete from notes data.
      let copyMeetingsData = JSON.parse(JSON.stringify(meetingsData));
      copyMeetingsData = copyMeetingsData.filter(obj => obj.id !== meetingId);
      setMeetingsData(copyMeetingsData);

      // Object is removed, make sure the details view is gone for it.
      setCurrentMeeting(null);

    } catch (error) {
      console.error(error.message);
    }
}

const handleBlurTitle = async (event) => {        
    // Update the title on backend
    if(currentMeeting && currentMeeting.title !== title) {
        await handleUpdateMeeting(currentMeeting.id, title);

        // Update current one as well
        let copyCurrentMeeting = JSON.parse(JSON.stringify(currentMeeting));
        copyCurrentMeeting.title = title;
        setCurrentMeeting(copyCurrentMeeting);

        // Update the data as well
        const copyMeetingsData = JSON.parse(JSON.stringify(meetingsData));
        const item = copyMeetingsData.find(obj => obj.id === currentMeeting.id);
        item.title = title;
        sortByTitle(copyMeetingsData);
        setMeetingsData(copyMeetingsData);
    }
};

async function handleUpdateMeeting(meetingId, title) {
    try {
      if(title === null) {
        throw new Error('handleUpdateMeeting: All params are null');
      }

      const idToken = await auth.currentUser.getIdToken();
      const theURL = process.env.REACT_APP_BASE_URL + Const.sMeetingsPath;

      let reqBody = {
        meetingId
      };

      if(title !== null) {
        reqBody[Const.sTitle] = title;
      }
    
      await fetch(theURL, {
        method: Const.HttpPatch,
        headers: new Headers({
          Authorization: idToken,
          Accept: Const.AppJson,
          [Const.ContentType]: Const.AppJson
        }),
        body: JSON.stringify(reqBody)
      });
  
    } catch (error) {
      console.error(error.message);
    }
  };

  async function handleCheckableUpdate(checkable, text, status) {
    const idToken = await auth.currentUser.getIdToken();
    const theURL = process.env.REACT_APP_BASE_URL + Const.sMeetingsCheckablesPath;
    const response = await fetch(theURL, {
      method: Const.HttpPatch,
      headers: new Headers({
        Authorization: idToken,
        Accept: Const.AppJson,
        [Const.ContentType]: Const.AppJson
      }),
      body: JSON.stringify({
        id: checkable.id,
        title: text,
        status
      })
    });

    // Check if the request was successful
    if(!response.ok) {
      throw new Error('Failed to update meeting checkable');
    }

    // Update data as well
    
    let copyMeetingsData = JSON.parse(JSON.stringify(meetingsData));

    let array = null;
    copyMeetingsData.forEach((meeting, index) => {
        if (meeting.id === currentMeeting.id) {          
          if(checkable.type === Const.sAgenda) {
            array = copyMeetingsData[index].data.agenda;
          } else if (checkable.type === Const.sAction) {
            array = copyMeetingsData[index].data.actionItems;
          }

          array.forEach((checkableItem, index2) => {
              if(checkableItem.id === checkable.id) {

                // We either change title of the action or status
                if(text !== null) {
                  array[index2].title = text;
                } else if(status !== null) {
                  array[index2].status = status;
                }                
              }
          });

          sortByTitle(array);
        }
    });

    setMeetingsData(copyMeetingsData);

    // Update current as well, this is a must!

    let copyCurrentMeeting = JSON.parse(JSON.stringify(currentMeeting));

    array = null;
    if(checkable.type === Const.sAgenda) {
      array = copyCurrentMeeting.data.agenda;
    } else if (checkable.type === Const.sAction) {
      array = copyCurrentMeeting.data.actionItems;
    }

    // TODO break for for each, convert to for
    array.forEach((checkableItem, index) => {
        if(checkableItem.id === checkable.id) {

          // We either change title of the action or status
          if(text !== null) {
            array[index].title = text;
          } else if(status !== null) {
            array[index].status = status;
          }
        }
    });

    sortByTitle(array);

    setCurrentMeeting(copyCurrentMeeting);
  }

  async function handleCheckableDelete(checkable) {
    const idToken = await auth.currentUser.getIdToken();
    const theURL = process.env.REACT_APP_BASE_URL + Const.sMeetingsCheckablesPath;
    const response = await fetch(theURL, {
      method: Const.HttpDelete,
      headers: new Headers({
        Authorization: idToken,
        Accept: Const.AppJson,
        [Const.ContentType]: Const.AppJson
      }),
      body: JSON.stringify({
        id: checkable.id
      })
    });

    // Check if the request was successful
    if(!response.ok) {
      throw new Error('Failed to delete meeting checkable');
    }

    // Update data as well
    
    let copyMeetingsData = JSON.parse(JSON.stringify(meetingsData));

    let array = null;
    copyMeetingsData.forEach((meeting, index) => {
        if (meeting.id === currentMeeting.id) {          
          if(checkable.type === Const.sAgenda) {
            array = copyMeetingsData[index].data.agenda;
          } else if (checkable.type === Const.sAction) {
            array = copyMeetingsData[index].data.actionItems;
          }

          array.forEach((checkableItem, index2) => {
              if(checkableItem.id === checkable.id) {
                array.splice(index2, 1);
              }
          });
        }
    });

    setMeetingsData(copyMeetingsData);

    // Update current as well

    let copyCurrentMeeting = JSON.parse(JSON.stringify(currentMeeting));

    if(checkable.type === Const.sAgenda) {
      array = copyCurrentMeeting.data.agenda;
    } else if (checkable.type === Const.sAction) {
      array = copyCurrentMeeting.data.actionItems;
    }

    array.forEach((checkableItem, index) => {
        if(checkableItem.id === checkable.id) {
          array.splice(index, 1);
        }
    });

    setCurrentMeeting(copyCurrentMeeting);
  }

  async function handleCheckableAdd(type) {
    setTimeout(() => {
      handleCheckableAddImpl(type);
    }, 0); // Postpone the execution
  }

  async function handleCheckableAddImpl(type) {
    // Do not start adding a new one while edit is in progress
    // We want to to commit successfully
    if(globalCheckableEditState) {
      return;
    }

    const idToken = await auth.currentUser.getIdToken();
    const theURL = process.env.REACT_APP_BASE_URL + Const.sMeetingsCheckablesPath;
    const response = await fetch(theURL, {
      method: Const.HttpPut,
      headers: new Headers({
        Authorization: idToken,
        Accept: Const.AppJson,
        [Const.ContentType]: Const.AppJson
      }),
      body: JSON.stringify({
        type,
        parentId: currentMeeting.id
      })
    });

    // Check if the request was successful
    if(!response.ok) {
      throw new Error('Failed to add meeting checkable');
    }

    const checkable = await response.json();
    
    // Update local data
    
    // Update data

    let copyMeetingsData = JSON.parse(JSON.stringify(meetingsData));

    let array = null;
    copyMeetingsData.forEach((meeting, index) => {
        if (meeting.id === currentMeeting.id) {          
          if(checkable.type === Const.sAgenda) {
            array = copyMeetingsData[index].data.agenda;
          } else if (checkable.type === Const.sAction) {
            array = copyMeetingsData[index].data.actionItems;
          }

          array.push(checkable);
        }
    });

    setMeetingsData(copyMeetingsData);
    
    // Update current

    let copyCurrentMeeting = JSON.parse(JSON.stringify(currentMeeting));

    if(checkable.type === Const.sAgenda) {
      array = copyCurrentMeeting.data.agenda;
    } else if (checkable.type === Const.sAction) {
      array = copyCurrentMeeting.data.actionItems;
    }

    array.push(checkable);

    sortByTitle(array);

    setCurrentMeeting(copyCurrentMeeting);
  }

  return (
    <VStack
        width={'100%'}
        h={'100vh'}
        alignItems={'top'}
        paddingLeft={6}
        paddingRight={2}
        paddingTop={2}
        paddingBottom={2}
    >
    <HStack>
    <Input
        ref={titleInputRef}
        fontSize={Const.XL}
        fontWeight={Const.Bold}
        value={title}
        border={Const.sNone}
        marginLeft={-4}
        onChange={(event) => {
            setTitle(event.target.value);
        }}
        onBlur={(event) => {
            handleBlurTitle(event);
        }}
        onKeyDown={(event) => {
            if (event.key === Const.sEnter) {
                // Remove focus
                titleInputRef.current.blur();
                event.preventDefault(); // Prevents any default action
            }
        }}
    />
    <Spacer />
    <Button
        leftIcon={<DeleteIcon/>}
        iconSpacing={0}
        size={Const.SM}
        height={'30px'}
        background={Const.Gray69}
        color={Const.Gray165}
        _hover={{ background: Const.Red600 }}
        fontSize={Const.XS}
        onClick={() => {
            handleDeleteMeeting(currentMeeting.id);
        }}
    />
    </HStack>

    <HStack alignItems={'top'}>

        <VStack
            width={'100%'}
            alignItems={'top'}
        >
            <Text
                align={Const.Left}
                fontSize={Const.MD}
                fontWeight={Const.Bold}
                w={'76px'}
                color={Const.Gray165}
                _hover={{ cursor: Const.sDefault }}
            >
                Agenda
            </Text>
            <Divider my={1} borderColor={Const.Gray100} />
            {
                currentMeeting.data && currentMeeting.data.agenda && currentMeeting.data.agenda.map((item, index) => (
                  item.status !== Const.sDone && // Filter out done items
                  <EditableCheckbox
                        key={index}
                        value={item.title}
                        object={item}
                        setGlobalCheckableEditState={setGlobalCheckableEditState}
                        onChange={handleCheckableUpdate}
                        onDelete={handleCheckableDelete}
                    />
                ))
            }
            <Button
                leftIcon={<AddIcon/>}
                size={Const.SM}
                minH={'30px'}
                width={'80px'}
                background={Const.Gray31}
                color={Const.Green500}
                _hover={{ background: Const.Gray69 }}
                onClick={() => {
                  setTimeout(() => {
                    handleCheckableAdd(Const.sAgenda);
                  }, 0); // Postpone the execution
                }}
            >
                Agenda
            </Button>
            
        </VStack>

        <VStack
            width={'100%'}
            alignItems={'top'}
        >
            <Text
                align={Const.Left}
                fontSize={Const.MD}
                fontWeight={Const.Bold}
                color={Const.Gray180}
            >
                Action items
            </Text>
            <Divider my={1} borderColor={Const.Gray100} />
            {
                currentMeeting.data && currentMeeting.data.actionItems && currentMeeting.data.actionItems.map((item, index) => (
                    item.status !== Const.sDone && // Filter out done items
                    <EditableCheckbox
                        key={index}
                        value={item.title}
                        object={item}
                        setGlobalCheckableEditState={setGlobalCheckableEditState}
                        onChange={handleCheckableUpdate}
                        onDelete={handleCheckableDelete}
                    />
                ))
            }
            <Button
                leftIcon={<AddIcon/>}
                size={Const.SM}
                minH={'30px'}
                width={'110px'}
                background={Const.Gray31}
                color={Const.Green500}
                _hover={{ background: Const.Gray69 }}
                onClick={() => {
                  setTimeout(() => {
                    handleCheckableAdd(Const.sAction)
                  }, 0); // Postpone the execution
                }}
            >
                Action item
            </Button>
        </VStack>

    </HStack>

    <Box h={4}/>

    <Text
        align={Const.Left}
        fontSize={Const.MD}
        fontWeight={Const.Bold}
        color={Const.Gray180}
    >
        Meeting notes
    </Text>
    <Divider my={1} borderColor={Const.Gray100} />
    <Box h={'100%'} overflowY={'auto'} maxH={'100%'}>
        <Accordion allowMultiple h={'100%'} defaultIndex={[0]}>
            {
                currentMeeting.data && currentMeeting.data.meetingNotes && currentMeeting.data.meetingNotes.map((item, index) => {
                    return (
                        <AccordionItem key={index} border={Const.sNone}>
                            <AccordionButton>
                                <Box as='span' flex='1' textAlign={Const.Left} fontSize={Const.SM} fontWeight={Const.Bold} color={Const.Gray180}>
                                    {fsDateToString(item.date)}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4} height={'36vh'}>
                                <MeetingNoteTextarea
                                    auth={auth}
                                    meetingNote={item}
                                    meetingsData={meetingsData}
                                    setMeetingsData={setMeetingsData}
                                    currentMeeting={currentMeeting}
                                    setCurrentMeeting={setCurrentMeeting}
                                />
                            </AccordionPanel>
                        </AccordionItem>
                    );
                })
            }
        </Accordion>
    </Box>

    </VStack>
    );
};

export default MeetingsDetails;
