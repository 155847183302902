import React, { useState, useEffect, useRef } from 'react';

import {
    Button,
    HStack,
    Spacer,
    Input,
    VStack,
    Textarea,
    Text
} from '@chakra-ui/react';

import { DeleteIcon, AddIcon, StarIcon, CopyIcon } from '@chakra-ui/icons';

import Const from './Constants';
import { sortByTitle } from './Utils';

const InterviewDetails = ({
    auth,
    interviewsData,
    setInterviewsData,
    currentInterview,
    setCurrentInterview,
    loadInterviewsData,
    handleAddInterview
}) => {
    // Used as a value for the title input
    const [title, setTitle] = useState(currentInterview ? currentInterview.data.title : '');
    const titleInputRef = useRef(null);

    const [body, setBody] = useState(currentInterview ? currentInterview.data.body : '');
    const bodyInputRef = useRef(null);

    const [aiSummary, setAiSummary] = useState(currentInterview ? currentInterview.data.aiSummary : '');
    const aiSummaryInputRef = useRef(null);

    const [isAISummaryLoading, setIsAISummaryLoading] = useState(false);

    useEffect(() => {
        if(currentInterview) {
          if(currentInterview.title !== title) {
            setTitle(currentInterview.data.title);
          }

          if(currentInterview.body !== body) {
            setBody(currentInterview.data.body);
          }
        }

        // If the current interview title is default one, set the focus on title input
        if((currentInterview.data.title === '' || currentInterview.data.title === Const.sPositionName) && titleInputRef.current) {
            titleInputRef.current.focus();
          } else if (currentInterview.data.body === '' && bodyInputRef.current) {
            bodyInputRef.current.focus();
          }

        // Close AI summary on current change
        setAiSummary('');
    // eslint-disable-next-line
    }, [currentInterview]);

    async function handleAISummary() {
        try {
            setIsAISummaryLoading(true);

            // Load all tasks from backend
            const queryParams = new URLSearchParams({
                title: currentInterview.data.title,
                body: body
            });
            const idToken = await auth.currentUser.getIdToken();
            const theURL = `${process.env.REACT_APP_BASE_URL}/interviews/summary?${queryParams}`;
        
            const response = await fetch(theURL, {
                headers: new Headers({
                    Authorization: idToken
                }),
            });
            const responseJson = await response.json();
            setAiSummary(responseJson.summary);
        } catch (error) {
            console.error(error.message);
        } finally {
            setIsAISummaryLoading(false);
        }
    }

    function handleCreateInterview() {
        // Button is enabled only for global or local templates, so we have a template here for sure.
        const interviewTemplate = currentInterview.data;
        handleAddInterview(Const.sInterview, interviewTemplate.id, interviewTemplate.title, interviewTemplate.body);
    }

    async function handleDeleteInterview(interviewId) {
        try {
          const idToken = await auth.currentUser.getIdToken();
          const theURL = process.env.REACT_APP_BASE_URL + Const.sInterviewsPath;
          await fetch(theURL, {
            method: Const.HttpDelete,
            headers: new Headers({
              Authorization: idToken,
              Accept: Const.AppJson,
              [Const.ContentType]: Const.AppJson
            }),
            body: JSON.stringify({
                interviewId
            })
          });

          // Update interview list
          await loadInterviewsData();

          // Interview is removed, make sure the details view is gone for it.
          setCurrentInterview(null);

        } catch (error) {
          console.error(error.message);
        }
    }

    async function handleUpdateInterview(interviewId, title, body) {
        try {
          if(title === null && body === null) {
            throw new Error('handleUpdateInterview: All params are null');
          }
    
          const idToken = await auth.currentUser.getIdToken();
          const theURL = process.env.REACT_APP_BASE_URL + Const.sInterviewsPath;
    
          let reqBody = {
            interviewId
          };

          if(title !== null) {
            reqBody[Const.sTitle] = title;
          }
    
          if(body !== null) {
            reqBody[Const.sBody] = body;
          }
        
          await fetch(theURL, {
            method: Const.HttpPatch,
            headers: new Headers({
              Authorization: idToken,
              Accept: Const.AppJson,
              [Const.ContentType]: Const.AppJson
            }),
            body: JSON.stringify(reqBody)
          });
        } catch (error) {
          console.error(error.message);
        }
    };

    // Title changed, handle that
    async function handleBlurTitle(event) {
        // Ignore this event if there were no changes.
        if(currentInterview.data.title === title) {
            return;
        }

        // - Update the current interview object.
        let copyCurrentInterview = JSON.parse(JSON.stringify(currentInterview));
        copyCurrentInterview.data.title = title;
        setCurrentInterview(copyCurrentInterview);

        // - Update the interview data
        let copyInterviewsData = JSON.parse(JSON.stringify(interviewsData));
        
        // Set the new body
        const objectToUpdate = copyInterviewsData[currentInterview.type].find(obj => obj.id === currentInterview.data.id);
        if(objectToUpdate) {
            objectToUpdate.title = title;

            // The sort order is alphabetical, make sure we preserve that.
            sortByTitle(copyInterviewsData[currentInterview.type]);
        }

        setInterviewsData(copyInterviewsData);

        // Update in the server as well
        handleUpdateInterview(currentInterview.data.id, title, null);
    }

    // Body changed, need to send the update to server
    async function handleBlurBody(event) {
        // Ignore this event if there were no changes.
        if(currentInterview.data.body === body) {
            return;
        }

        // - Update the current interview object.
        let copyCurrentInterview = JSON.parse(JSON.stringify(currentInterview));
        copyCurrentInterview.data.body = body;
        setCurrentInterview(copyCurrentInterview);

        // - Update the interview data
        let copyInterviewsData = JSON.parse(JSON.stringify(interviewsData));
        
        // Set the new body
        const objectToUpdate = copyInterviewsData[currentInterview.type].find(obj => obj.id === currentInterview.data.id);
        if(objectToUpdate) {
            objectToUpdate.body = body;
        }
        setInterviewsData(copyInterviewsData);

        // Update in the server as well
        handleUpdateInterview(currentInterview.data.id, null, body);
    }

    return (
        currentInterview && <VStack w={'100%'} align={Const.Left} margin={'10px'}>
            <HStack>
                <Input
                    ref={titleInputRef} // Set the reference to the input
                    isReadOnly={currentInterview.type === Const.sGlobal}
                    fontSize={Const.XL}
                    fontWeight={Const.Bold}
                    value={title}
                    border={Const.sNone}
                    _hover={{ cursor: currentInterview.type === Const.sGlobal ? Const.sDefault : 'text' }}
                    onChange={(event) => {
                        setTitle(event.target.value);
                    }}
                    onBlur={(event) => {
                        handleBlurTitle(event);
                    }}
                    onKeyDown={(event) => {
                        if (event.key === Const.sEnter) {
                            // Remove focus
                            titleInputRef.current.blur();
                            event.preventDefault(); // Prevents any default action
                        }
                    }}
                />
                <Spacer />
                { /* Create interview button */
                currentInterview.type !== Const.sInterview && <Button
                    leftIcon={<AddIcon/>}
                    size={Const.SM}
                    height={'30px'}
                    minWidth={'120px'}
                    background={Const.DoneColor}
                    color={Const.White}
                    _hover={{ background: Const.DoneLightColor }}
                    fontSize={Const.XS}
                    onClick={() => {
                        handleCreateInterview();
                    }}>
                    Create interview
                </Button>
                }
                { /* Interview AI summary button */
                currentInterview.type === Const.sInterview && <Button
                    leftIcon={<StarIcon/>}
                    isLoading={isAISummaryLoading} 
                    loadingText={Const.sAISummary}
                    size={Const.SM}
                    height={'30px'}
                    minWidth={'120px'}
                    background={Const.DoneColor}
                    color={Const.White}
                    _hover={{ background: Const.DoneLightColor }}
                    fontSize={Const.XS}
                    onClick={() => {
                        handleAISummary();
                    }}>
                    {Const.sAISummary}
                </Button>
                }
                { /* Delete button */
                currentInterview.type !== Const.sGlobal && <Button
                    leftIcon={<DeleteIcon/>}
                    iconSpacing={0}
                    size={Const.SM}
                    height={'30px'}
                    background={Const.Gray69}
                    color={Const.Gray165}
                    _hover={{ background: Const.Red600 }}
                    fontSize={Const.XXS}
                    onClick={() => {
                        handleDeleteInterview(currentInterview.data.id);
                    }}
                />
                }
            </HStack> {/* end of header */}
            <HStack flex='1'>
                <VStack w='100%' h={'100%'}>
                    <Textarea
                        ref={bodyInputRef} // Set the reference to the input
                        isReadOnly={currentInterview.type === Const.sGlobal}
                        fontSize={Const.MD}
                        flex='1' // Allow Textarea to grow and take up remaining space
                        height={'100%'}
                        value={body}
                        resize={Const.sNone}
                        border={Const.sNone}
                        placeholder={currentInterview.type === Const.sInterview ? Const.sCreateInterview : Const.sCreateInterviewTemplate }
                        _placeholder={{ color: Const.Gray100 }}
                        _hover={{ cursor: currentInterview.type !== Const.sGlobal ? Const.sDefault : 'text' }}
                        onChange={(event) => {
                            setBody(event.target.value);
                        }}
                        onBlur={(event) => {
                            handleBlurBody(event);
                        }}
                    />
                </VStack>

                { aiSummary &&
                <>
                {/* Green Delimiter Line */}
                
                <VStack
                    w='100%'
                    alignItems={Const.Left}
                    h={'100%'}
                    border={'1px'}
                    padding={2}
                    borderColor={Const.DoneColor}
                    borderRadius={Const.MD}
                >
                    <HStack>
                        <Text
                            align={Const.Left}
                            fontSize={Const.MD}
                            fontWeight={Const.Bold}
                            color={Const.DoneLightColor}
                        >
                            AI Summary
                        </Text>
                        <Spacer />
                        { /* Copy button */ }
                        <Button
                            leftIcon={<CopyIcon/>}
                            iconSpacing={0}
                            size={Const.SM}
                            height={'30px'}
                            background={Const.DoneColor}
                            color={Const.White}
                            _hover={{ background: Const.DoneLightColor }}
                            fontSize={Const.XXS}
                            onClick={() => {
                                navigator.clipboard.writeText(aiSummary);
                            }}
                        />
                    </HStack>
                        <Textarea
                        ref={aiSummaryInputRef} // Set the reference to the input
                        // isReadOnly={currentInterview.type !== Const.sInterview}
                        fontSize={Const.MD}
                        flex={'1'} // Allow Textarea to grow and take up remaining space
                        value={aiSummary}
                        resize={Const.sNone}
                        border={Const.sNone}
                        _placeholder={{ color: Const.Gray100 }}
                        // _hover={{ cursor: currentInterview.type !== Const.sInterview ? Const.sDefault : 'text' }}
                        onChange={(event) => {
                            setAiSummary(event.target.value);
                        }}
                        onBlur={(event) => {
                            // handleBlurBody(event);
                        }}
                    />
                </VStack>
                </>
                }
            </HStack>
        </VStack>
    );
};

export default InterviewDetails;
