import React, { useState, useEffect, useRef } from 'react';
import {
  Flex,
  Box,
  useToast
} from '@chakra-ui/react';

import './App.css';
import { auth } from './Firebase';
// import { analytics } from './Firebase'; TODO unused for now

import Const from './Constants';

import { showSuccessToast, handleAuthStateChangeCallback } from './Utils';

import SideBar from './SideBar';
import Notes from './Notes';
import Tasks from './Tasks';
import Interviews from './Interviews';
import SignInView from './SignInView';
import TaskDetailsBar from './TaskDetailsBar';
import Meetings from './Meetings';
import Feedback from './Feedback';
import Profile from './Profile';

function App() {
  const toast = useToast();

  const [appMode, setAppMode] = useState(null); // Load in tasks mode - FYI first auth try is there
  const [userConfig, setUserConfig] = useState(null);
  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state
  const [subscription, setSubscription] = useState({
    state: undefined,
    subscriptionId: null, // unused in front end for now
    timestamp: null // unused in front end for now
  });
  
  const [isSidebarOpen] = useState(true);
  // const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  
  // App level data context
  const [currentTask, setCurrentTask] = useState(null);
  const [tasks, setTasks] = useState({
    'Todo': [],
    'In Progress': [],
    'Done': []
  });
  const [interviewsData, setInterviewsData] = useState({
    global: [], // Global template
    local: [], // Local template
    interview: []
  });

  const [notesData, setNotesData] = useState([]);

  // The data for 1 on 1 meetings
  const [oneOnOnesData, setOneOnOnesData] = useState([]);

  // The data for ordinary meetings
  const [meetingsData, setMeetingsData] = useState([]);

  // Do not show competed tasks by default
  const [showCompletedTasks, setShowCompletedTasks] = useState(false);
  const isFirstRender = useRef(true); // Track if it's the first render

  async function handleGetUser() {
    try {
      const idToken = await auth.currentUser.getIdToken();
      const theURL = `${process.env.REACT_APP_BASE_URL}/user`;

      const response = await fetch(theURL, {
        headers: new Headers({
          Authorization: idToken
        })
      });
      const user = await response.json();
      return user;
    } catch (error) {
      console.error(error.message);
    }
  }

  async function loadUserConfig() {
    try {
      const idToken = await auth.currentUser.getIdToken();
      const theURL = `${process.env.REACT_APP_BASE_URL}/user/config`;

      const response = await fetch(theURL, {
        headers: new Headers({
          Authorization: idToken
        })
      });
      const userConfig = await response.json();
      return userConfig;
    } catch (error) {
      console.error(error.message);
    }
  }

  async function handleUpdateUserConfig(configPart) {
    try {
      const idToken = await auth.currentUser.getIdToken();
      const theURL = process.env.REACT_APP_BASE_URL + '/user/config';
      const response = await fetch(theURL, {
        method: Const.HttpPatch,
        headers: new Headers({
          Authorization: idToken,
          Accept: Const.AppJson,
          [Const.ContentType]: Const.AppJson
        }),
        body: JSON.stringify(configPart)
      });

      // Check if the request was successful
      if(!response.ok) {
        throw new Error('Failed to update user config');
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  async function onSignIn(signedIn) {
    if(signedIn) {
      const uc = await loadUserConfig();
      setAppMode((uc && uc.local && uc.local.appMode) ? uc.local.appMode : Const.sTasks);
      setUserConfig(uc);
    } else {
      setAppMode(Const.sSignIn);
    }
  }

  // Simple routing
  useEffect(() => {
    async function handleSubscriptionCheck() {
      const user = await handleGetUser();

      setSubscription({
        state: user.subscription.state,
        subscriptionId: user.subscription.subscriptionId, // unused in front end for now
        timestamp: user.subscription.timestamp // unused in front end for now
      });

      if(user.subscription.state === true) {
        showSuccessToast(toast, `${Const.sAppName} Pro`, 'Thank you for subscribing!');
      }
    }

    // Take care of sign in
    if(!isSignedIn) {
      handleAuthStateChangeCallback(auth, isSignedIn, setIsSignedIn, onSignIn, setSubscription);
    }

    // Handle subscription logic
    if(window.location.pathname === '/lemon') {
      // We are back from a payment provider, check the subscription state and show a toast for the user
      setTimeout(() => {
        handleSubscriptionCheck();
      }, 2000); // We give it 2 sec so all initialization will be done before the check
    }

  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(userConfig && userConfig.local && userConfig.local.appMode !== appMode) {
      handleUpdateUserConfig({
        appMode
      });
    }
  // eslint-disable-next-line
  }, [appMode]);

  useEffect(() => {
    if (isFirstRender.current) {
      // Skip the first call
      isFirstRender.current = false;
      return;
    }

    loadTasksData();
  // eslint-disable-next-line
  }, [showCompletedTasks]);

  async function loadTasksData() {
    try {
      // Load all tasks from backend
      const queryParams = new URLSearchParams({
        includeDone: showCompletedTasks
      });
      const idToken = await auth.currentUser.getIdToken();
      const theURL = `${process.env.REACT_APP_BASE_URL}/tasks?${queryParams}`;

      const response = await fetch(theURL, {
        headers: new Headers({
          Authorization: idToken
        })
      });
      const tasks = await response.json();
      setTasks(tasks);
    } catch (error) {
      console.error(error.message);
    }
  }

  async function handleUpdateTask(taskId, title, description, done, status) {
    try {
      if(title === null && description === null && done === null && status === null) {
        throw new Error('handleUpdateTask: All params are null');
      }

      const idToken = await auth.currentUser.getIdToken();
      const theURL = process.env.REACT_APP_BASE_URL + '/tasks';

      let body = {
        taskId
      };

      if(title !== null) {
        body[Const.sTitle] = title;
      }

      if(description !== null) {
        body[Const.sDescription] = description;
      }

      if(done !== null) {
        body[Const.sDone] = done;
      }

      if(status !== null) {
        body[Const.sStatus] = status;
      }

      await fetch(theURL, {
        method: Const.HttpPatch,
        headers: new Headers({
          Authorization: idToken,
          Accept: Const.AppJson,
          [Const.ContentType]: Const.AppJson
        }),
        body: JSON.stringify(body)
      });
  
      // Now update the task data
      await loadTasksData();
  
    } catch (error) {
      console.error(error.message);
    }
  };

  function appModeToComponentMap(appMode) {
    if(appMode === Const.sNotes) {
      return <Notes auth={auth} notesData={notesData} setNotesData={setNotesData} />;
    }
    else if (appMode === Const.sTasks) {
      return <Tasks
        auth={auth}
        isSignedIn={isSignedIn}
        setIsSignedIn={setIsSignedIn}
        setSubscription={setSubscription}
        appMode={appMode}
        setAppMode={setAppMode}
        currentTask={currentTask}
        setCurrentTask={setCurrentTask}
        tasks={tasks}
        setTasks={setTasks}
        loadTasksData={loadTasksData}
        handleUpdateTask={handleUpdateTask}
        showCompletedTasks={showCompletedTasks}
        setShowCompletedTasks={setShowCompletedTasks}
      />
    }
    else if(appMode === Const.sInterviews) {
      return <Interviews auth={auth} interviewsData={interviewsData} setInterviewsData={setInterviewsData} />;
    }
    else if(appMode === Const.s1on1Meetings) {
      return <Meetings
                type={Const.s1on1}
                auth={auth}
                meetingsData={oneOnOnesData}
                setMeetingsData={setOneOnOnesData}
             />;
    }
    else if(appMode === Const.sMeetings) {
      return <Meetings
                type={Const.sMeeting}
                auth={auth}
                meetingsData={meetingsData}
                setMeetingsData={setMeetingsData}
             />;
    }
    else if(appMode === Const.sFeedback) {
      return <Feedback auth={auth} toast={toast}/>;
    }
    else if(appMode === Const.sProfile) {
      return <Profile
                auth={auth}
                setAppMode={setAppMode}
                setIsSignedIn={setIsSignedIn}
                subscription={subscription}
                setSubscription={setSubscription}
                toast={toast} />
    }
    else {
      return null;
    }
  }

  return (
    <Box className='App'>
      {
      appMode === Const.sSignIn ?
      <SignInView
          auth={auth}
          setAppMode={setAppMode}
          isSignedIn={isSignedIn}
          setIsSignedIn={setIsSignedIn}
          setSubscription={setSubscription} />
      :
      <>
      <Flex>
            {isSidebarOpen && <SideBar auth={auth} appMode={appMode} setAppMode={setAppMode} setIsSignedIn={setIsSignedIn} subscription={subscription} />}
            { /* Mail area */
              appModeToComponentMap(appMode)
            }
            { /* Task details bar  */
            currentTask !== null && appMode === Const.sTasks &&
              <TaskDetailsBar
                auth={auth}
                currentTask={currentTask}
                setCurrentTask={setCurrentTask}
                loadTasksData={loadTasksData}
                handleUpdateTask={handleUpdateTask}
              />
            }
      </Flex>
      </>
      }
    </Box>
  );
}

export default App;
