import React, { useState, useEffect } from 'react';
import {
    Flex,
    Box,
    Text,
    VStack
} from '@chakra-ui/react';

import { isEqual, getTodayFSDate, sameDay } from './Utils';

import Const from './Constants';

import MeetingsBar from './MeetingsBar';
import MeetingsDetails from './MeetingsDetails';

const Meetings = ({ type, auth, meetingsData, setMeetingsData }) => {
    const [currentMeeting, setCurrentMeeting] = useState(null);

    useEffect(() => {
      loadMeetingsData(type);

      setCurrentMeeting(null);
    // eslint-disable-next-line
    }, [type]);

    useEffect(() => {
        // Lazy loading of the data of the meeting
        if(currentMeeting && !currentMeeting.data) {
          loadMeetingDataById(currentMeeting.id);
        }
    // eslint-disable-next-line
    }, [currentMeeting]);

    function addTodaysMeetingNotes(meeting) {
      // If there is no note in the list with the current date, add one.

      const today = getTodayFSDate();

      // No need to add id or parent, we'll do this on server when/if saving.
      const emptyTodaysMeetingNote = {
        meetingId: meeting.id,
        date: today,
        body: ''
      };

      if(meeting.data.meetingNotes.length === 0) {
        meeting.data.meetingNotes.push(emptyTodaysMeetingNote);
      } else if (meeting.data.meetingNotes[0].date !== today) {
        if(!sameDay(today, meeting.data.meetingNotes[0].date)) { // Only add it if the first one is not from today
          meeting.data.meetingNotes.unshift(emptyTodaysMeetingNote);
        }
      }

      return meeting;
    }

    async function loadMeetingsData(type) {
        try {
          const queryParams = new URLSearchParams({
            type
          });
          const idToken = await auth.currentUser.getIdToken();
          const theURL = `${process.env.REACT_APP_BASE_URL}${Const.sMeetingsPath}?${queryParams}`;
    
          const response = await fetch(theURL, {
            headers: new Headers({
              Authorization: idToken
            })
          });
          const data = await response.json();
          setMeetingsData(data);
        } catch (error) {
          console.error(error.message);
        }
      }

      async function loadMeetingDataById(id) {
        try {
          const queryParams = new URLSearchParams({
            id
          });
          const idToken = await auth.currentUser.getIdToken();
          const theURL = `${process.env.REACT_APP_BASE_URL}${Const.sMeetingsPath}?${queryParams}`;
      
          const response = await fetch(theURL, {
            headers: new Headers({
              Authorization: idToken
            })
          });

          let meeting = await response.json();

          // Add today's meeting notes on top to fill in the details.
          meeting = addTodaysMeetingNotes(meeting);
      
          // Update meetings data immutably
          const updatedMeetingsData = meetingsData.map(meetingItem => {
            if (meetingItem.id === id) {
              return {
                ...meetingItem,
                data: meeting.data // Replace the data property with the new one
              };
            }
            return meetingItem;
          });
      
          // Set the new array of meetingsData
          await setMeetingsData(updatedMeetingsData);
      
          // Update current if necessary
          if (currentMeeting && currentMeeting.id === meeting.id) {
            if (!currentMeeting.data || !isEqual(currentMeeting.data, meeting.data)) {
              await setCurrentMeeting({
                ...currentMeeting,
                data: meeting.data
              });
            }
          }
      
        } catch (error) {
          console.error(error.message);
        }
      }

    return (
        <Box
            display={'flex'}
            justifyContent={Const.sCenter}
            alignItems={Const.sCenter}
            height={'100vh'}
            width={'100vw'}
        >
            <Flex
              w={'100%'}
              alignItems={currentMeeting ? 'stretch' : Const.sCenter}
            >
                <MeetingsBar
                  type={type}
                  auth={auth}
                  meetingsData={meetingsData}
                  setMeetingsData={setMeetingsData}
                  currentMeeting={currentMeeting}
                  setCurrentMeeting={setCurrentMeeting}
                />
                { currentMeeting ? (
                <MeetingsDetails
                  type={type}
                  auth={auth}
                  meetingsData={meetingsData}
                  setMeetingsData={setMeetingsData}
                  currentMeeting={currentMeeting}
                  setCurrentMeeting={setCurrentMeeting}
                />) : (
                  <VStack 
                      w={'100%'}
                  >
                  <Text
                      fontSize={Const.MD}
                      fontWeight={Const.Bold}
                      color={Const.Gray100}
                  >
                      {`Select a ${type} or create a new one`}
                  </Text>
                </VStack>
                )
                }
            </Flex>
        </Box>
    );
};

export default Meetings;
