import React, { useState, useEffect } from 'react';

import {
    Box,
    Button,
    HStack,
    Spacer,
    Text,
    Divider,
    Input,
    Textarea
} from '@chakra-ui/react';

import { CheckIcon, CloseIcon, DeleteIcon } from '@chakra-ui/icons';

import Const from './Constants';
import { handleDeleteTask } from './Utils';

const TaskDetailsBar = ({ auth, currentTask, setCurrentTask, loadTasksData, handleUpdateTask }) => {
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [title, setTitle] = useState(currentTask.title);

    const [isEditingDescription, setIsEditingDescription] = useState(false);
    const [description, setDescription] = useState(currentTask.description);

    useEffect(() => {
        currentTask.title = title;
        setCurrentTask(currentTask);
    // eslint-disable-next-line
    }, [title]);

    useEffect(() => {
        currentTask.description = description;
        setCurrentTask(currentTask);
    // eslint-disable-next-line
    }, [description]);

    // When user selects another task
    useEffect(() => {
        setTitle(currentTask.title);
        setDescription(currentTask.description);
    }, [currentTask]);

    const handleBlurTitle = () => {
        setIsEditingTitle(false);

        handleUpdateTask(currentTask.id, currentTask.title, null, null, null);
    };

    const handleBlurDescription = () => {
        setIsEditingDescription(false);

        handleUpdateTask(currentTask.id, null, currentTask.description, null, null);
    };

  return (
    <Box
        width={'480px'}
        minW={'480px'}
        maxW={'480px'}
        background={Const.Gray46}
        padding={'12px'}
        h={'100vh'}
    >
        <HStack>
        <Button
            leftIcon={<CheckIcon/>}
            size={Const.SM}
            height={'30px'}
            background={ currentTask.done ? Const.DoneColor : Const.Gray36}
            color={Const.Gray165}
            _hover={{ background: currentTask.done ? Const.DoneLightColor : Const.Gray69 }}
            fontSize={Const.XS}
            onClick={() => {
                currentTask.done = !!!currentTask.done;
                setCurrentTask(currentTask);

                // Update only done status.
                handleUpdateTask(currentTask.id, null, null, currentTask.done, null);
              }}
          >
              { !currentTask.done ? 'Mark complete' : 'Completed' }
          </Button>
          <Spacer />
          <Button
            leftIcon={<DeleteIcon/>}
            iconSpacing={0}
            size={Const.SM}
            height={'30px'}
            background={Const.Gray31}
            color={Const.Gray165}
            _hover={{ background: Const.Red600 }}
            fontSize={Const.XS}
            onClick={async () => {
                setCurrentTask(null);

                await handleDeleteTask(auth, currentTask.id);
                
                loadTasksData();
            }}
          >
          </Button>
          <Button
            leftIcon={<CloseIcon/>}
            iconSpacing={0}
            size={Const.SM}
            height={'30px'}
            background={Const.Gray31}
            color={Const.Gray165}
            _hover={{ background: Const.Gray69 }}
            fontSize={Const.XXS}
            onClick={() => {
                setCurrentTask(null);
            }}
          >
          </Button>
        </HStack>
        <Divider
            my={4}
            borderColor={Const.SelectColor}
        />
        { // Title
            isEditingTitle ? (
                <Input
                    autoFocus
                    fontSize={Const.XL}
                    fontWeight={Const.Bold}
                    value={title}
                    border={Const.sNone}
                    onChange={(event) => {
                        setTitle(event.target.value);
                    }}
                    onBlur={handleBlurTitle}
                    onKeyDown={(event) => {
                      if (event.key === Const.sEnter) {
                          handleBlurTitle();
                          event.preventDefault(); // Prevents any default action
                      }
                  }}
              />
            ) : (
                <Text
                    fontSize={Const.XL}
                    fontWeight={Const.Bold}
                    align={Const.Left}
                    marginTop={'8px'}
                    _hover={{ cursor: 'text' }}
                    onClick={() => {
                        setIsEditingTitle(true);
                    }}
                >
                    {title}
                </Text>
            )
        }
        <Text
            fontSize={Const.SM}
            fontWeight={Const.Bold}
            align={Const.Left}
            color={Const.Gray165}
            marginTop={'14px'}
        >
            Description
        </Text>
        { // Description
            <Textarea
                value={description}
                autoFocus={isEditingDescription}
                fontSize={Const.MD}
                resize={Const.sNone}
                height={'80vh'}
                marginTop={'6px'}
                padding={isEditingDescription ? '6px' : '0px'} // Remove padding when not editing
                readOnly={!isEditingDescription} // Make it readOnly when not in editing mode
                placeholder={'Enter the description here'}
                _placeholder={{ color: Const.Gray100 }}  // Setting placeholder text color
                border={isEditingDescription ? '1px solid' : Const.sNone} // Show border only when editing
                onBlur={handleBlurDescription}
                onChange={(event) => {
                    setDescription(event.target.value);
                }}
                onClick={() => {
                    if (!isEditingDescription) setIsEditingDescription(true);
                }}
                _hover={{
                    cursor: 'text'
                }}              
          />
        }
    </Box>
  );
};

export default TaskDetailsBar;
