import { useState } from 'react';

import {
    Box,
    Button,
    Text,
    Divider,
    useDisclosure,
    Menu,
    MenuList,
    MenuItem,
} from '@chakra-ui/react';

import Const from './Constants';

const SideBarButton = ({id, current, icon, title, action, menuEnabled, menuItems, group, onSetGroup}) => {
    // Open states for the menu
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

    const handleRightClick = (event) => {
        event.preventDefault(); // Prevent the default right-click menu
        setMenuPosition({ x: event.clientX, y: event.clientY });
        onOpen();
      };

    return (
        <>
            <Button
                leftIcon={
                    <Box color={current === true ? Const.Orange400 : Const.White}>
                        {icon}
                    </Box>
                }
                size={Const.SM}
                height={'30px'}
                minH={'30px'}
                width={'100%'}
                background={ current === true ? Const.Gray69 : Const.Gray46 }
                color={current === true ? Const.Orange400 : Const.White}
                justifyContent={'flex-start'}
                _hover={{ background: Const.Gray69 }}
                onClick={action}
                onContextMenu={menuEnabled ? handleRightClick : null}
            >
                <Box
                    as={'span'}
                    whiteSpace={'nowrap'}
                    overflow={'hidden'}
                    textOverflow={'ellipsis'}
                    maxW={'100%'} // Ensure the text respects the button's width
                    >
                    {title}
                </Box>
            </Button>

            {isOpen && (
            <Menu isOpen={isOpen} onClose={onClose}>
            <MenuList
                background={Const.Gray46}
                borderColor={Const.Gray64}
                style={{
                position: 'absolute',
                top: `${menuPosition.y}px`,
                left: `${menuPosition.x}px`,
                }}
            >
                <Text
                    fontSize={Const.SM}
                    fontWeight={Const.Bold}
                    color={Const.Gray180}
                    paddingLeft={2}
                    background={Const.Gray46}
                    textAlign={Const.Left}
                    cursor={Const.sDefault}
                >
                    Move to
                </Text>
                {
                    menuItems && menuItems.map((item, index) => (
                        group !== item && <MenuItem
                            key={index}
                            color={Const.White}
                            background={Const.Gray46}
                            fontSize={Const.SM}
                            _hover={{ background: Const.Gray64 }}
                            onClick={() => onSetGroup(id, item) }
                        >
                            { item }
                        </MenuItem>
                    ))
                }

                {/* Divider */}
                <Divider borderColor={Const.Gray100} />

                <MenuItem
                    color={Const.Green500}
                    fontWeight="bold"
                    background={Const.Gray46}
                    fontSize={Const.SM}
                    onClick={() => {}}
                    _hover={{ background: Const.Gray64 }}
                >
                    + new group
                </MenuItem>

            </MenuList>
            </Menu>
        )}
        </>
    );
};

export default SideBarButton;

