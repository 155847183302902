import { useState, useEffect, useRef } from 'react';

import {
    Button,
    VStack,
    Accordion, 
    AccordionItem, 
    AccordionButton, 
    AccordionPanel, 
    AccordionIcon,
    Input
} from '@chakra-ui/react';

import { AddIcon } from '@chakra-ui/icons';

import SideBarButton from './SideBarButton';

import Const from './Constants';
import { sortByTitle, handleUpdateNote } from './Utils';

const AccordionButtonInput = ({ auth, group, notesData, setNotesData, onClick }) => {
  const [groupTitle, setGroupTitle] = useState(group);
  const [groupNewTitle, setGroupNewTitle] = useState(group);

  const groupInputRef = useRef(null);

  useEffect(() => {
    setGroupTitle(group);
    setGroupNewTitle(group);
  }, [group]);

  const handleUpdateNoteGroup = async (groupTitle, groupNewTitle) => {
    try {
      const idToken = await auth.currentUser.getIdToken();
      const theURL = process.env.REACT_APP_BASE_URL + '/notes/group';
      const response = await fetch(theURL, {
        method: Const.HttpPatch,
        headers: new Headers({
          Authorization: idToken,
          Accept: Const.AppJson,
          [Const.ContentType]: Const.AppJson
        }),
        body: JSON.stringify({
          groupTitle: groupTitle,
          groupNewTitle: groupNewTitle
        })
      });

      // Check if the request was successful
      if(!response.ok) {
        throw new Error('Failed to update note group');
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  const handleBlur = async (event) => {
    if(groupTitle !== groupNewTitle) { // TODO handle the empty case as well
      // Update the note title on backend
      handleUpdateNoteGroup(groupTitle, groupNewTitle);

      // Update the notes data
      const copyNotesData = JSON.parse(JSON.stringify(notesData));

      copyNotesData.forEach(note => { // TODO handle other case
        if(note.group === groupTitle) {
          note.group = groupNewTitle;
        }
      });

      setNotesData(copyNotesData);

      // Update the orig title as well for the next time.
      setGroupTitle(groupNewTitle);
    }
  };

  return (
    <AccordionButton
      height={'30px'}
      paddingLeft={0}
      _hover={{ background: Const.Gray69 }}
    >
      <Input
          height={'30px'}
          flex={'1'}
          ref={groupInputRef} // Set the reference to the input
          fontSize={Const.SM}
          fontWeight={Const.Bold}
          textAlign={Const.Left}
          color={Const.Gray180}
          value={groupNewTitle}
          border={Const.sNone}
          _hover={{ background: 'none' }}
          onClick={(event) => {
            event.preventDefault();
          }}
          onChange={(event) => {
            setGroupNewTitle(event.target.value);
          }}
          onBlur={(event) => {
            handleBlur(event);
          }}
          onKeyDown={(event) => {
            if(event.key === Const.sEnter) {
              // Remove focus
              groupInputRef.current.blur();
              event.preventDefault(); // Prevents any default action
            } else if (event.key === ' ') {
              // we cancel space event so section is not expanded collapsed.
              // So we need to handle it manually here.

              // Insert space at the cursor position
              const cursorPosition = event.target.selectionStart;
              const newValue = groupNewTitle.slice(0, cursorPosition) + ' ' + groupNewTitle.slice(cursorPosition);
              setGroupNewTitle(newValue);

              // Set cursor position after the space
              setTimeout(() => {
                groupInputRef.current.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
              }, 0);

              // Prevent space from expanding/collapsing the accordion
              event.preventDefault();
            }
          }}
      />
      <AccordionIcon onClick={onClick} />
    </AccordionButton>
  );
};

const NotesBar = ({
  auth,
  notesData,
  setNotesData,
  currentNote,
  setCurrentNote
}) => {
  const [notesGroup, setNotesGroup] = useState([Const.sOther]);
  const [expandedGroups, setExpandedGroups] = useState([]);

  const [firstInvoke, setFirstInvoke] = useState(true);

  useEffect(() => {
    setNotesGroup(getNotesGroup());
  // eslint-disable-next-line
  }, [notesData]);

  useEffect(() => {
    if(firstInvoke) {
      setExpandedGroups(getAllGroups());
      setFirstInvoke(false);
    }
  // eslint-disable-next-line
  }, [notesGroup]);

  // Utility function to extract get note groups
  const getNotesGroup = () => {
    let ng = [];
    let thereIsOther = false;
    notesData.forEach(note => {
      if(note.group) { // Shall never be empty on the backend
        ng.push(note.group);
      }

      if(note.group === Const.sOther) {
        thereIsOther = true;
      }
    });

    // Remove other group if it is there to add at the end
    ng = ng.filter(group => group !== Const.sOther);

    // Sort the groups
    ng.sort();

    // Make them unique
    ng = [...new Set(ng)];

    // Add Other at the end
    if(notesData.length !== 0 && thereIsOther) {
      ng.push(Const.sOther);
    }

    return ng;
  }

  const handleAddNote = async () => {
    try {
      const idToken = await auth.currentUser.getIdToken();
      const theURL = process.env.REACT_APP_BASE_URL + '/notes';
      const response = await fetch(theURL, {
        method: Const.HttpPost,
        headers: new Headers({
          Authorization: idToken,
          Accept: Const.AppJson,
          [Const.ContentType]: Const.AppJson
        })
      });

      // Check if the request was successful
      if(!response.ok) {
        throw new Error('Failed to add note');
      }

      // Parse the added note from the response
      const addedNote = await response.json();

      // Add into notes data store and sort accordingly.
      const copyNotesData = JSON.parse(JSON.stringify(notesData));
      copyNotesData.push(addedNote);
      sortByTitle(copyNotesData);
      await setNotesData(copyNotesData);

      // Set the newly added note as the current note
      setCurrentNote(addedNote);
    } catch (error) {
      console.error(error.message);
    }
  };

  async function handleOnSetGroup(noteId, group) {
    // Update in backend
    handleUpdateNote(auth, noteId, null, null, group);

    // Add into notes data store and sort accordingly.
    const copyNotesData = JSON.parse(JSON.stringify(notesData));
    
    const noteIndex = copyNotesData.findIndex(note => note.id === noteId);
    if(noteIndex !== -1) {
        copyNotesData[noteIndex].group = group;
    }
    
    setNotesData(copyNotesData);
  }

  function getAllGroups() {
    let expandedGroups = [];
    notesGroup.forEach((group, index) => {
      expandedGroups.push(index);
    });

    return expandedGroups;
  }

  const handleAccordionButtonClick = (index) => {
    setExpandedGroups((prevExpandedGroups) => {
      if (prevExpandedGroups.includes(index)) {
        return prevExpandedGroups.filter((i) => i !== index);
      } else {
        return [...prevExpandedGroups, index];
      }
    });
  };

  return (
    <VStack
        w={'260px'}
        minW={'260px'}
        maxW={'260px'}
        height={'100vh'}
        background={Const.Gray46}
        padding={'10px'}
        spacing={'4px'}
        overflowY={'auto'}>
        <Accordion
          index={expandedGroups} // TODO this turns off the collapse feature, handle manually on click event?
          allowMultiple
          width={'100%'}
        >
          { /* Needs to be a map here */ }
          {
            notesGroup.map((group, index) => (
              
              <AccordionItem
                key={index}
                border={Const.sNone}
              >
                <h2>
                  <AccordionButtonInput
                    auth={auth}
                    group={group}
                    notesData={notesData}
                    setNotesData={setNotesData}
                    onClick={() => handleAccordionButtonClick(index)}
                  />
                </h2>
                <AccordionPanel pb={4}>
                {!notesData.empty && 
                  notesData.map((note, index) => (
                    (note.group === group || ((note.group === undefined || note.group === '') && group === Const.sOther)) &&
                    <SideBarButton
                      key={note.id}
                      id={note.id}
                      current={currentNote && note.id === currentNote.id}
                      title={!note.title ? 'New Note' : note.title}
                      action={() => setCurrentNote(note)}
                      menuEnabled={true}
                      menuItems={notesGroup}
                      group={group}
                      onSetGroup={handleOnSetGroup}
                    />
                  ))
                }
                </AccordionPanel>
              </AccordionItem>
            ))
        }
      </Accordion>
      <Button
        leftIcon={<AddIcon />}
        size={Const.SM}
        minH={'26px'}
        width={'100%'}
        background={Const.Gray46}
        color={Const.Green500}
        _hover={{ background: Const.Gray69 }}
        onClick={() => handleAddNote()}
      >
        Add note
      </Button>
    </VStack>
  );
};

export default NotesBar;
