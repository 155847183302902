import React, { useState, useEffect, useRef } from 'react';

import {
    Button,
    HStack,
    Spacer,
    Input,
    VStack,
    Textarea
} from '@chakra-ui/react';

import { DeleteIcon } from '@chakra-ui/icons';

import Const from './Constants';
import { sortByTitle, handleUpdateNote } from './Utils';

const NoteDetails = ({ auth, notesData, setNotesData, currentNote, setCurrentNote }) => {
    // Used as a value for the title input
    const [title, setTitle] = useState(currentNote ? currentNote.title : '');
    const titleInputRef = useRef(null);

    const [body, setBody] = useState(currentNote ? currentNote.body : '');
    const bodyInputRef = useRef(null);

    useEffect(() => {
        if(currentNote) {
          if(currentNote.title !== title) {
            setTitle(currentNote.title);
          }

          if(currentNote.body !== body) {
            setBody(currentNote.body);
          }

          // If the current note title is empty set the focus on title input
          if(currentNote.title === '' && titleInputRef.current) {
            titleInputRef.current.focus();
          } else if (currentNote.body === '' && bodyInputRef.current) {
            bodyInputRef.current.focus();
          }
        }
    // eslint-disable-next-line
    }, [currentNote]);

    async function handleDeleteNote(noteId) {
        try {
          const idToken = await auth.currentUser.getIdToken();
          const theURL = process.env.REACT_APP_BASE_URL + '/notes';
          await fetch(theURL, {
            method: Const.HttpDelete,
            headers: new Headers({
              Authorization: idToken,
              Accept: Const.AppJson,
              [Const.ContentType]: Const.AppJson
            }),
            body: JSON.stringify({
              noteId
            })
          });

          // Delete from notes data.
          let copyNotesData = JSON.parse(JSON.stringify(notesData));
          copyNotesData = copyNotesData.filter(obj => obj.id !== noteId);
          setNotesData(copyNotesData);

          // Note is removed, make sure the details view is gone for it.
          setCurrentNote(null);

        } catch (error) {
          console.error(error.message);
        }
    }

    const handleBlurTitle = async (event) => {        
        // Update the note title on backend
        if(currentNote && currentNote.title !== title) {
            await handleUpdateNote(auth, currentNote.id, title, null, null);

            // Update current note as well
            let copyCurrentNote = JSON.parse(JSON.stringify(currentNote));
            copyCurrentNote.title = title;
            setCurrentNote(copyCurrentNote);

            // Update the notes data
            const copyNotesData = JSON.parse(JSON.stringify(notesData));
            const note = copyNotesData.find(obj => obj.id === currentNote.id);
            note.title = title;
            sortByTitle(copyNotesData);
            setNotesData(copyNotesData);
        }
    };

    const handleBlurBody = async (event) => {
      // Update the note body on backend
      if(currentNote && currentNote.body !== body) {
          await handleUpdateNote(auth, currentNote.id, null, body, null);

          // Update current note as well
          let copyCurrentNote = JSON.parse(JSON.stringify(currentNote));
          copyCurrentNote.body = body;
          setCurrentNote(copyCurrentNote);

          // Update the notes data
          const copyNotesData = JSON.parse(JSON.stringify(notesData));
          const note = copyNotesData.find(obj => obj.id === currentNote.id);
          note.body = body;
          setNotesData(copyNotesData);
      }
  };

    return (
        currentNote && <VStack w={'100%'} align={Const.Left} margin={'10px'}>
            <HStack>
                <Input
                    ref={titleInputRef} // Set the reference to the input
                    fontSize={Const.XL}
                    fontWeight={Const.Bold}
                    value={title}
                    border={Const.sNone}
                    onChange={(event) => {
                        setTitle(event.target.value);
                    }}
                    onBlur={(event) => {
                        handleBlurTitle(event);
                    }}
                    onKeyDown={(event) => {
                        if (event.key === Const.sEnter) {
                            // Remove focus
                            titleInputRef.current.blur();
                            event.preventDefault(); // Prevents any default action
                        }
                    }}
                />
                <Spacer />
                <Button
                    leftIcon={<DeleteIcon/>}
                    iconSpacing={0}
                    size={Const.SM}
                    height={'30px'}
                    background={Const.Gray69}
                    color={Const.Gray165}
                    _hover={{ background: Const.Red600 }}
                    fontSize={Const.XS}
                    onClick={() => {
                        handleDeleteNote(currentNote.id);
                    }}
                />
            </HStack>
            <Textarea
                ref={bodyInputRef} // Set the reference to the input
                fontSize={Const.MD}
                flex={'1'} // Allow Textarea to grow and take up remaining space
                value={body}
                resize={Const.sNone}
                border={Const.sNone}
                placeholder={'Enter the note here'}
                _placeholder={{ color: Const.Gray100 }}
                _hover={{
                  cursor: 'text'
                }}
                onChange={(event) => {
                    setBody(event.target.value);
                }}
                onBlur={(event) => {
                    handleBlurBody(event);
                }}
            />
        </VStack>
    );
};

export default NoteDetails;
