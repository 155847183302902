import {
  Flex,
  VStack,
  Text,
  Spacer
} from '@chakra-ui/react';

import { GoogleAuthProvider, EmailAuthProvider } from 'firebase/auth';

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import Const from './Constants';

// Configure FirebaseUI.
const uiConfig = {
  // Popup sign in flow rather than redirect flow.
  signInFlow: 'popup',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    GoogleAuthProvider.PROVIDER_ID,
    EmailAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false
  }
};

function SignInView({
  auth,
  setAppMode,
  isSignedIn,
  setIsSignedIn,
  setSubscription
}) {
  return (    
      <Flex marginTop={24}
        justify={Const.sCenter}
      >
        <VStack spacing={2}
          padding={6}
          borderRadius={Const.MD}
          border={'1px solid'}
          borderColor={Const.Gray64}
        >
          <Text fontWeight={Const.Bold} fontSize={Const.XL} color={Const.ActiveColor}>{Const.sAppName}</Text> {/* Solar Flare */}
          <Text fontSize={Const.SM} color={Const.Gray165}>Choose your sign in option</Text> {/* Solar Flare */}
          <Spacer/>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
        </VStack>
      </Flex>
  );
}

export default SignInView;
