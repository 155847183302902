import React, { useState, useEffect } from 'react';
import {
    Box,
    Text,
    Input,
    HStack,
    VStack,
    Button,
    Spacer
} from '@chakra-ui/react';

import { AddIcon, CheckIcon } from '@chakra-ui/icons';

import Const from './Constants';

import {
  handleDeleteTask
} from './Utils';

import TaskTopBar from './TaskTopBar';

const kColumnSpacing = '10px';
const kColumnHeaderHeight = '30px';
const kTaskHeight = '80px';

function pixelToNum(px) {
  const str = px.slice(0, -2);
  return parseInt(str);
}

/* unused
function numToPixel(num) {
  return String(num) + 'px';
}
*/

const Task = ({
  auth,
  appMode,
  task,
  status,
  index,
  onDragStart,
  onDragEnd,
  onUpdate,
  onDelete,
  currentTask,
  setCurrentTask,
  loadTasksData,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(task.title);

  // Automatically focus when a task is new and should be edited
  useEffect(() => {
    if (task.title === '') {
      setIsEditing(true);
    }
  }, [task.title]);

  const handleBlur = async () => {
    setIsEditing(false);

    if (title.trim() === '') {
      await onDelete(auth, task.id);

      // Now update the task data
      loadTasksData();
    } else {
      onUpdate(task.id, title, null /* No description */);
    }
  };

  const handleClick = () => {
    setCurrentTask(task);
  };

  const handleChange = (e) => {
    setTitle(e.target.value);
  };

  return (
    <HStack
      draggable={task.id !== 0}
      onClick={handleClick}
      onDragStart={(e) => onDragStart(e, task, status, index)}
      onDragEnd={(e) => onDragEnd(e, task)}
      minW={'240px'}
      maxW={'240px'}
      minH={kTaskHeight}
      padding={'12px'}
      border={'1px'}
      borderRadius={'6px'}
      alignItems={'flex-start'} // Align items to the top
      borderColor={
        currentTask && currentTask.id === task.id
          ? Const.SelectColor
          : Const.Gray64
      }
      background={task.id !== 0 ? Const.Gray36 : Const.Gray64}
      _hover={{
        background: task.id !== 0 ? Const.Gray46 : Const.Gray64,
        cursor: Const.sPointer,
      }}
    >
      {task.id !== 0 ? ( // Check for drag placeholder
        <>
          <Box
            as={'button'}
            cursor={Const.sPointer}
            borderRadius={'full'}
            border={'1px solid'}
            borderColor={task.done ? Const.DoneColor : Const.Gray165}
            width={'18px'} // Explicitly set width
            height={'18px'} // Explicitly set height
            display={'flex'}
            alignItems={Const.sCenter}
            justifyContent={Const.sCenter}
            marginTop={'2px'}
            mr={0} // Add some margin between the icon and the text
            _hover={{ borderColor: task.done ? Const.DoneLightColor : Const.Gray100 }}
            onClick={(e) => {
              e.stopPropagation(); // Prevent triggering the click event on the task
              onUpdate(task.id, null, null, !task.done, null);
            }}
          >
            <CheckIcon
              boxSize={2}
              color={task.done ? Const.DoneColor : Const.Gray165}
            />
          </Box>
          {isEditing ? (
            <Input
              autoFocus
              value={title}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={(event) => {
                if (event.key === Const.sEnter) {
                  handleBlur();
                  event.preventDefault(); // Prevents any default action
                }
              }}
            />
          ) : (
            <Text
              color={task.done ? Const.Gray100 : Const.White}
              align={Const.Left}
              flex={1} // This allows the text to take the remaining space
            >
              {task.title}
            </Text>
          )}
        </>
      ) : null}
    </HStack>
  );
};

const Column = ({
  auth,
  title,
  tasks,
  onDragStart,
  onDragEnd,
  onDragOver,
  onDrop,
  onAddTask,
  onUpdateTask,
  onDeleteTask,
  dropPosition,
  currentTask,
  setCurrentTask,
  loadTasksData
}) => {
  return (
      <VStack
          padding={'10px'}
          spacing={kColumnSpacing}
          align={'start'}
          borderColor={Const.Gray64}
          borderRadius={'8'}
          minW={'280px'} // minW of task +2x padding
          minH={'90vh'}
      >
        <HStack
          padding={'10px'}
          width={'100%'}
        >
          <Text
              color={Const.White}
              fontSize={Const.LG}
              fontWeight={Const.Bold}
              align={Const.Left}
              height={kColumnHeaderHeight}
            >
              {title}
            </Text>
            <Spacer/>
            <Button
              leftIcon={<AddIcon boxSize={3}/>}
              iconSpacing={0}
              size={Const.SM}
              height={'30px'}
              background={Const.Gray31}
              color={Const.Green500}
              _hover={{ background: Const.Gray64 }}
              onClick={() => onAddTask(title)}
            >
            </Button>
        </HStack>
          <VStack
            flex={'1'}
            width={'100%'}
            overflowY={'auto'} // Enable vertical scrolling
            maxH={'calc(100vh - 120px)'}
            padding={'10px'}
            spacing={kColumnSpacing}
            align={'start'}
            onDragOver={(e) => onDragOver(e, tasks, title, 'VStack')} // Allow dropping at the end of the list
            onDrop={(e) => onDrop(e, title)} // This one is working, no need to have onDrop on task
          >
          {tasks && tasks.map((task, index) => {

            return (<Box key={task.id}>
                <Task 
                    key={task.id}
                    auth={auth}
                    task={task}
                    status={title}
                    index={index}
                    onDragStart={onDragStart} 
                    onDragEnd={onDragEnd} 
                    onUpdate={onUpdateTask} 
                    onDelete={onDeleteTask}
                    currentTask={currentTask}
                    setCurrentTask={setCurrentTask}
                    loadTasksData={loadTasksData}
                />
            </Box>);
        }
        )}
          <Button
              leftIcon={<AddIcon/>}
              size={Const.SM}
              minH={'30px'}
              width={'100%'}
              background={Const.Gray31}
              color={Const.Green500}
              _hover={{ background: Const.Gray69 }}
              onClick={() => onAddTask(title)}
          >
              Add task
          </Button>
        </VStack>
      </VStack>
  );
};

const Tasks = ({
  auth,
  isSignedIn,
  setIsSignedIn,
  setSubscription,
  appMode,
  setAppMode,
  currentTask,
  setCurrentTask,
  tasks,
  setTasks,
  loadTasksData,
  handleUpdateTask,
  showCompletedTasks,
  setShowCompletedTasks }) => {

    useEffect(() => {
      loadTasksData();
    // eslint-disable-next-line
    }, []);

    // The task we are dragging
    const [draggedTask, setDraggedTask] = useState({task: null, status: null, index: null});

    // This is drag shadow position
    const [dropPosition, setDropPosition] = useState({ index: null, column: null });

    const onDragStart = (e, task, status, index) => {
      setDraggedTask({task, status, index});
      setDropPosition({ index: null, column: null });
    };
  
    const onDragEnd = (e) => {
      setDraggedTask({task: null, status: null, index: null});
      setDropPosition({ index: null, column: null });
    };

    const addDragTask = (status) => {
      // tasks[status].push({id: 0});

      tasks[status].splice(dropPosition.index, 0, {id: 0});
    }

    // Removes the drag task from all, will not set.
    const removeDragTask = () => {
      Object.keys(tasks).forEach(status => {
        // Filter out tasks with id of 0
        tasks[status] = tasks[status].filter(task => task.id !== 0);
      });
    }

    /*
    const findDragTask2 = (status) => {
      const isFound = tasks[status].some(task => task.id === 0);
      return isFound;
    }
    */

    const setDragTask = (status) => {
      removeDragTask();

      addDragTask(status);

      setTasks(tasks);
    }

    const onDragOver = (e, tasks, title, source) => {
      e.preventDefault(); // Prevent the default behavior, which is necessary to allow the drop action.
    
      setDragTask(title); // Set the current column title where the drag action is occurring.
    
      // Get the scrollable container where the tasks are listed.
      const scrollableContainer = e.currentTarget;
    
      // Calculate the position of the scrollable container relative to the viewport.
      const targetRect = scrollableContainer.getBoundingClientRect();
    
      // Get the current vertical scroll position of the container.
      const scrollTop = scrollableContainer.scrollTop;
    
      // Calculate the Y position of the cursor relative to the scrollable container's content.
      // This is done by subtracting the top position of the container from the cursor's Y position
      // and then adding the scroll position to account for any scrolling that has occurred.
      let relativeY = e.clientY - targetRect.top + scrollTop;
    
      // Determine the potential new position for the task by dividing the relative Y position
      // by the height of a task (including spacing) to get the appropriate index.
      let newPosition = relativeY / (pixelToNum(kTaskHeight) + pixelToNum(kColumnSpacing));
      newPosition = Math.floor(newPosition); // Round down to the nearest whole number to get a valid index.
    
      // Ensure the new position is within the bounds of the task list.
      if (newPosition < 0) {
        newPosition = 0; // Prevents positioning before the first task.
      } else if (newPosition > tasks.length) {
        newPosition = tasks.length + 1; // Prevents positioning beyond the last task.
      }
    
      // Update the state to reflect the new drop position.
      // This sets where the task would be dropped within the column.
      setDropPosition({ index: newPosition, column: title });
    };

  const handleUpdateTasksOrder = async (tasks) => {
    try {
      if(tasks.empty) return;

      // Create a map from task ids to new order to reordner on server side.
      const taskIdToOrder = {};
      tasks.forEach((task, index) => {
        taskIdToOrder[task.id] = index;
      });

      let body = {
        taskIdToOrder
      };

      const idToken = await auth.currentUser.getIdToken();
      const theURL = process.env.REACT_APP_BASE_URL + '/tasks/order';
      await fetch(theURL, {
        method: Const.HttpPatch,
        headers: new Headers({
          Authorization: idToken,
          Accept: Const.AppJson,
          [Const.ContentType]: Const.AppJson
        }),
        body: JSON.stringify(body)
      });  
    } catch (error) {
      console.error(error.message);
    }
  }

  const onDrop = async (e, status) => {
    e.preventDefault();
    if (!draggedTask.task || !dropPosition.column) return;

    removeDragTask();

    // Remove the Task from Its Original Position
    tasks[draggedTask.status].splice(draggedTask.index, 1);
    // Insert the Task into the New Position
    tasks[dropPosition.column].splice(dropPosition.index, 0, draggedTask.task);

    // Send drag status updated to server.
    handleUpdateTask(draggedTask.task.id, null, null, null, dropPosition.column);

    // Update/reorder the order of the tasks in that status 
    handleUpdateTasksOrder(tasks[dropPosition.column]);

    setDraggedTask({task: null, status: null, index: null});
    setDropPosition({ index: null, column: null });

    setTasks(tasks);
  };

    const handleAddTask = async (status) => {
      try {
        const idToken = await auth.currentUser.getIdToken();
        const theURL = process.env.REACT_APP_BASE_URL + '/tasks';
        await fetch(theURL, {
          method: Const.HttpPost,
          headers: new Headers({
            Authorization: idToken,
            Accept: Const.AppJson,
            [Const.ContentType]: Const.AppJson
          }),
          body: JSON.stringify({
            status
          })
        });

        // Now update the task data
        await loadTasksData();

      } catch (error) {
        console.error(error.message);
      }
    };

    const columns = [
      'Todo',
      'In Progress',
      'Done'
    ];

    return (
      <Box width={'100%'}>
        <TaskTopBar
          appMode={appMode}
          showCompletedTasks={showCompletedTasks}
          setShowCompletedTasks={setShowCompletedTasks}/>
        <HStack w={'100%'} align='start' padding={'10px'}>
          {columns.map((col) => {
            return <Column
              key={col}
              auth={auth}
              title={col}
              tasks={tasks[col]}
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
              onDragOver={onDragOver}
              onDrop={onDrop}
              onAddTask={handleAddTask}
              onUpdateTask={handleUpdateTask}
              onDeleteTask={handleDeleteTask}
              dropPosition={dropPosition}
              currentTask={currentTask}
              setCurrentTask={setCurrentTask}
              loadTasksData={loadTasksData}/>
          })}
        </HStack>
      </Box>
    );
};

export default Tasks;
