import {
    Button,
    VStack,
    Box
} from '@chakra-ui/react';

import { AddIcon } from '@chakra-ui/icons';

import SideBarButton from './SideBarButton';

import Const from './Constants';
import { sortByTitle } from './Utils';

const MeetingsBar = ({
    type,
    auth,
    meetingsData,
    setMeetingsData,
    currentMeeting,
    setCurrentMeeting
}) => {

    const handleAddMeeting = async () => {
        try {
          const idToken = await auth.currentUser.getIdToken();
          const theURL = process.env.REACT_APP_BASE_URL + Const.sMeetingsPath;
          const response = await fetch(theURL, {
            method: Const.HttpPost,
            headers: new Headers({
              Authorization: idToken,
              Accept: Const.AppJson,
              [Const.ContentType]: Const.AppJson
            }),
            body: JSON.stringify({
              type
            })
          });
    
          // Check if the request was successful
          if(!response.ok) {
            throw new Error('Failed to add meeting');
          }
    
          // Parse the added note from the response
          const addedMeeting = await response.json();
    
          // Add into data store and sort accordingly.
          const copyMeetingsData = JSON.parse(JSON.stringify(meetingsData));
          copyMeetingsData.push(addedMeeting);
          sortByTitle(copyMeetingsData);
          await setMeetingsData(copyMeetingsData);
    
          // Set the newly added note as the current note
          setCurrentMeeting(addedMeeting);
        } catch (error) {
          console.error(error.message);
        }
      };

  return (
    <VStack
        w={'240px'}
        minW={'240px'}
        maxW={'240px'}
        height={'100vh'}
        background={Const.Gray46}
        padding={'10px'}
        spacing={'4px'}
    >
      <Box
        w={'100%'}
        flex={'1'} // Ensures the scrollable container takes up remaining space
        overflowY={'auto'} // Enables vertical scrolling
      >
        { !meetingsData.empty && meetingsData.map((item, index) => (
          <SideBarButton
              key={item.id}
              current={currentMeeting && item.id === currentMeeting.id}
              title={item.title ? item.title : 'New meeting'}
              action={() => {
                setCurrentMeeting(item);
              }}
          />
      ))}
      </Box>
      <Button
            leftIcon={<AddIcon/>}
            size={Const.SM}
            minH={'26px'}
            width={'100%'}
            background={Const.Gray46}
            color={Const.Green500}
            _hover={{ background: Const.Gray69 }}
            onClick={ () => { handleAddMeeting() } }
        >
          {`Add ${type === Const.s1on1 ? '1:1' : Const.sMeeting}`}
      </Button>
    </VStack>
  );
};

export default MeetingsBar;
