const Const = {
    /* Names */
    sAppName: 'FlowHero',
    sTasks: 'Tasks',
    sNotes: 'Notes',
    sSignIn: 'SignIn',
    s1on1Meetings: 'One on Ones',
    sMeetings: 'Meetings',
    sInterviews: 'Interviews',
    sFeedback: 'Feedback',
    sProfile: 'Profile',
    sPositionName: 'Position: Name',
    sOther: 'Other',
    /* Keys */
    sTitle: 'title',
    sBody: 'body',
    sGroup: 'group',
    sDescription: 'description',
    sDone: 'done',
    sOpen: 'open',
    sStatus: 'status',
    sOrder: 'order',
    sGlobal: 'global',
    sLocal: 'local',
    sInterview: 'interview',
    sTemplate: 'template',
    sCenter: 'center',
    sNone: 'none',
    HttpPost: 'POST',
    HttpPatch: 'PATCH',
    HttpDelete: 'DELETE',
    HttpPut: 'PUT',
    AppJson: 'application/json',
    ContentType: 'Content-Type',
    sInterviewsPath: '/interviews',
    sMeetingsPath: '/meetings',
    sMeetingsNotesPath: '/meetings/notes',
    sMeetingsCheckablesPath: '/meetings/checkables',
    sFeedbacksPath: '/feedbacks',
    sDefault: 'default',
    SNone: 'none',
    sPointer: 'pointer',
    sAgenda: 'agenda',
    sAction: 'action',
    sEnter: 'Enter',
    s1on1: '1on1',
    sMeeting: 'meeting',
    sFeature: 'feature',
    /* Sizes */
    XXS: 10,
    XS: 'xs',
    SM: 'sm',
    MD: 'md',
    LG: 'lg',
    XL: 'xl',
    Bold: 'bold',
    Left: 'left',
    /* Colors */
    White: '#f6f6f6',
    ActiveColor: '#db4a2b',
    DoneColor: '#22543D',
    DoneLightColor: '#276749',
    SelectColor: '#3182CE',
    Red500: '#E53E3E',
    Red600: '#C53030',
    Red700: '#9B2C2C',
    Gray31: '#1f1f1f',
    Gray36: '#242424',
    Gray46: '#2e2e2e',
    Gray64: '#404040',
    Gray69: '#454545',
    Gray100: '#646464',
    Gray165: '#a5a5a5',
    Gray180: '#b4b4b4',
    Green500: '#38A169',
    Orange400: '#ED8936',
    /* Texts */
    sAISummary: 'AI Summary',
    sCreateInterviewTemplate: 'Create your own interview template.\n\n  1. Set the position title at the top.\n  2. Add the template content here.\n  3. Create interviews with one click from the template.',
    sCreateInterview: 'Create blank interview.\n\n  1. Add position title and candidate\'s name at the top.\n  2. Type notes here during the interview or copy/past the interview transcript.\n  3. Use one click AI Summary to spend 10 seconds instead of an hour for high quality interview summary.\n  4. Copy/past the summary into your candidate tracking system.'
};
  
export default Const;
